<script setup lang="ts">
interface Props {
  filled: boolean
}

const { filled = true } = defineProps<Props>()
const { icons } = useDesign()

defineOptions({
  name: 'MovieRatingStar',
})
</script>

<template>
  <Icon
    :name="filled ? icons.STAR : icons.STAR_OUTLINE"
    class="fill-primary size-4"
  />
</template>
